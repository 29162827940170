import { React, useState, useContext } from "react";
import Footer from "../components/footer";

import { useNavigate, useLocation } from "react-router-dom";
import http from "../components/http";
import { AuthContext } from "../context/authContext";
import toast from "react-hot-toast";

function Register(props) {
  const [isOpen, setIsOpen] = useState(false);
const toggleOpen = () => {
  setIsOpen((open) => !open);
};
  const post = useLocation().state;

  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const [username, postUser] = useState(post?.username || "");
  const [password, passUser] = useState("");
  const [email, emailUser] = useState(post?.email || "");

  const [error, setError] = useState(false);
  //Select box
  let [role, setRole] = useState("");

  // const handleSelect = () => {
  //  setvalue(value);
  //};
  // if (currentUser.role == 3) {
  //  setUser = true;
  // }
  const options = [
    { label: "Staff", value: "001" },
    { label: "Admin", value: "002" },
  ];
  // Registration
  const registerHandler = async (e) => {
    e.preventDefault();
    setError(false);
    try {
      //const res = await axios.post("/auth/register", {
      post
        ? await http.put(`/users/${post.id}`, {
            ...post,
            username,
            password,
            email,
            role,
            firm: currentUser.firm,
        
          })
        : await http.post(`auth/register`, {
            username,
            password,
            email,
            role,
            firm: currentUser.firm,
           
          });
      toast.success("Post was successful");
      navigate("/");
    } catch (err) {
      toast.error(err.response.data);
      setError(true);
    }
  };

  return (
    <div class="templatemo-content col-1 light-gray-bg">
      <div class="templatemo-content-widget templatemo-login-widget white-bg">
        <header class="text-center">
          <div class="square"></div>
          <h1>Rent And Flex</h1>
          <p>Excellent Property Managers</p>
        </header>
        {error && (
          <span style={{ color: "red" }}> {error} Registration failed</span>
        )}
        <form
          method="post"
          onSubmit={registerHandler}
          class="templatemo-login-form"
        >
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-addon">
                <i class="fa fa-user fa-fw"></i>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Username"
                required="true"
                value={username}
                onChange={(e) => postUser(e.target.value)}
              />
            </div>
          </div>

          <div class="form-group">
            <div class="input-group">
              <div class="input-group-addon">
                <i class="fa fa-user fa-fw"></i>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Enter Email"
                required="true"
                value={email}
                onChange={(e) => emailUser(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="input-group">
              
              <input
                  type={isOpen?"text":"password"}
                  value={password}
                className="form-control"
                placeholder="Enter Password"
                required="true"
                onChange={(e) => passUser(e.target.value)}
              />
              <div className="input-group-addon">
              <i className={isOpen?"fa fa-key fa-fw":"fa fa-eye"}onClick={toggleOpen}></i>
              </div>
            </div>

          </div>

          
          <div className="form-group">
            <div className="">
              <div>
                <span>Select Role Type</span>
              </div>

              <select
                onChange={(e) => setRole(e.target.value)}
                className="form-control"
                value={role}
              >
                {options.map((option) => (<>
                  <option value="" defaultValue="selected" hidden="hidden">
                  Choose here
                </option>
                  <option key={option.value} value={option.value}>{option.label}</option>
                  </>))}
              </select>
            </div>
          </div>

          <div class="form-group">
            <button type="submit" class="templatemo-blue-button width-100">
              Register
            </button>
          </div>
        </form>
      </div>
      <div class="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
        <p>
          Not a registered user yet?{" "}
          <strong>
            <a href="#" class="blue-text">
              Sign up now!
            </a>
          </strong>
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Register;
