import React from 'react'
import { Link } from 'react-router-dom';

export default function PlainCards() {

    const options = [
        { label: "Generate Statement", value: "", style:"green", link:'reports',  },
        { label: "Account Files", value: "", style:"blue", link:'files', },
       // { label: "Expired Rents", value: expireRents.length, style:"pink", link:'defaults' },
      ];
  return (
     
<div className="templatemo-flex-row flex-content-row">
{options.map((option) => (<>
    <div className="templatemo-content-widget white-bg col-2">
        <i className="fa fa-times"></i>
        <div className={`circle ${option.style}-bg`}></div>
        <Link to={`/${option.link}`}>

      <h2 className="templatemo-inline-block">{option.label}</h2>
</Link>
        
      

    
       
      </div>
                  </>))}
    
   
      
    </div>
  )
}
