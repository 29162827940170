import http from "../components/http"

export async function useProperties() {
  try{
    const res = await http.get(`property`);
    return res.data;
}
catch(error)
{
throw new Error(error)
}
}


export const useProperty=async(firm)=>{
    try{
          const res = await http.get(`property/${firm}`);
          return res.data;
    }
  catch(error)
  {
throw new Error(error)
  }
  }