import React, {useState} from "react";
import Footer from "../components/footer";
import Nav from "../components/nav";
import Sidebar from "../components/sideBar";
import NewUserList from "../list/newUserList";
import FileUpload from "../components/fileUpload";
import PropertyList from "../list/propertyList";
import FileList from "../list/fileList";

function FilePage(props) {
  const [show, setShow] = useState(false);

  return (
    <>
      <div class="templatemo-flex-row">
        <Sidebar />
        <div class="templatemo-content col-1 light-gray-bg">
          <Nav />
          <div class="templatemo-content-container">
           <button
              onClick={() => setShow(!show)}
              className="btn btn-default templatemo-view-img-btn"
            >
              {show === true ? "close" : "Upload New Files"}
            </button> <div class="templatemo-flex-row flex-content-row">
            
            {/* The table */}
            {show ? <FileUpload /> : <FileList/> }
              
            </div>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default FilePage;
