import http, { fetcher, marketUrlEndpoint, Url } from "../components/http"
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

export function useMarkets() {
  const { data, error, isLoading } = useSWR(marketUrlEndpoint,
    fetcher,{ revalidateOnFocus: false, revalidateOnReconnect: true }
  );

  return {
    market: data,
    mkloading:isLoading,
    mkerror:error,
  };
}

export function useMarket(firm){
    let url = marketUrlEndpoint + firm;
    const { data, error, isLoading } = useSWR(
      url,
      fetcher,{ revalidateOnFocus: false, revalidateOnReconnect: true }
    );
    return {
        
      market: data,
      mkloading:isLoading,
      mkerror:error,
    };
  }

  export const useFirms=async()=>{
    try{
          const res = await http.get('market');
          return res.data;
    }
  catch(error)
  {
throw new Error(error)
  }
  }