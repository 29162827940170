import React, {useContext} from "react";
import Nav from "../components/nav";
import Sidebar from "../components/sideBar";
import  { propertyUrlEndpoint } from "../components/http.jsx";
import { AuthContext } from "../context/authContext.js";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { useProperty } from "../data/property.js";
import DefaultList from "../list/DefaultList.jsx";



function Defaults() {
  const { currentUser } = useContext(AuthContext);
  let endPoint = propertyUrlEndpoint + currentUser.firm;
  const { properties, prloading, prerror,} = useProperty(currentUser.firm);
  let num = 1;
  if (prloading) return <p>{"Properties are Loading"}</p>;
  if (prerror) return <p>{prerror.message}</p>;

  return (
    <div>
      <div class="templatemo-flex-row">
        <Sidebar />
        <div class="templatemo-content col-1 light-gray-bg">
          <Nav />
         <DefaultList/>
        </div>
      </div>
    </div>
  );
}

export default Defaults;
