import React from 'react'
import Footer from '../components/footer'
import Sidebar from '../components/sideBar'
import Nav from '../components/nav'
import PlainCards from '../components/plainCards'

export default function Account() {
  return (
    <>
    <div className="templatemo-flex-row">
      <Sidebar />
      <div className="templatemo-content col-1 light-gray-bg">
        <Nav />
        <div className="templatemo-content-container">
          
          <PlainCards/>
          
          <Footer />
        </div>
      </div>
    </div>
  </>  )
}
