import React, { useContext, useRef } from "react";
import $ from "jquery";
import { findDOMNode } from "react-dom";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/authContext";
function Sidebar(props) {
  const { currentUser, logOut } = useContext(AuthContext);
  
  const componentRef = useRef();
  const handleToggle = () => {
    const el = findDOMNode(componentRef.current);
    $(el).slideToggle();
  };
  return (
    <div className="templatemo-sidebar">
      <header className="templatemo-site-header">
        <div className="square"></div>
        <h1>Rent And Flex</h1>
        <p style={{color:"greenyellow"}}>Excellent Property Managers</p>
      </header>
      <div className="profile-photo-container">
        
        <div className="profile-photo-overlay"></div>
      </div>
      <form className="templatemo-search-form" role="search">
        <div className="input-group">
          <button type="submit" className="fa fa-search"></button>
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            name="srch-term"
            id="srch-term"
          />
        </div>
      </form>
      <div className="mobile-menu-icon" onClick={(e) => handleToggle(e)}>
        <i className="fa fa-bars"></i>
      </div>
      <nav className="templatemo-left-nav" ref={componentRef}>
        <ul>
          <li>
            <Link to="/" className="active">
              <i className="fa fa-home fa-fw"></i>Dashboard
            </Link>
          </li>
          <li>
            <Link to="/accounts">
              <i className="fa fa-home fa-fw"></i>Accounts
            </Link>
          </li>
        
          <li>
            <Link to="/profile/">
              <i className="fa fa-home fa-fw"></i>Profiles
            </Link>
          </li>
          <li>
            <Link to="/defaults/">
              <i className="fa fa-bar-chart fa-fw"></i>Expired Rents
            </Link>
          </li>
         
          <li>
            <Link to="/property/">
              <i className="fa fa-map-marker fa-fw"></i>Properties
            </Link>
          </li>
          <li>
            <Link to="/payment/">
              <i className="fa fa-database fa-fw"></i>Payments
            </Link>
          </li>
          
          
          <li>
            <Link to="/user/">
              <i className="fa fa-users fa-fw"></i>Manage Users
            </Link>
          </li>
        
          {currentUser && (
            <li onClick={logOut}>
           <a>
              <i className="fa fa-eject fa-fw"></i>Sign Out
            </a></li>
          ) }
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;
