import React, { useContext, useState } from "react";
import http from "./http";

import "react-quill/dist/quill.snow.css";
import { AuthContext } from "../context/authContext";
import toast from "react-hot-toast";
function FileUpload() {
  const { currentUser } = useContext(AuthContext);
  const [title, titleRef] = useState("");

  const [error, setError] = useState(false);


  //picture states
  const [file, selectedFile] = useState([]);

  var fileSelectedHandler = (event) => {
    selectedFile(event.target.files[0]);
  };
  //Upload Images
  const upload = async () => {
    try {
      const data = new FormData();
      const filename = Date.now() + file.name;
      data.append("name", filename);
      data.append("file", file);
      const attachPics = await http.post(`uploadFile`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return attachPics.data;
    } catch (error) {
      setError(true);
      console.log(error.response);
    }
  };
  //Submit Handler
  const writeHandler = async (e) => {
    e.preventDefault();
    const img = await upload();
    setError(false);
     
    if(img)try {
      const postItems = {
        title,
        username: currentUser.username,
        file:  img,
        firm: currentUser.firm,
        };

        const res = await http.post('file', postItems);
           toast.success(res.data)
      /*if (file) {
        const data = new FormData();
        const filename = Date.now() + file.name;
        data.append("name", filename);
        data.append("file", file);
        postItems.photo = state?.photo || filename;

        try {
          const attachPics = await http.post(`upload`, data, {
            headers: { "Content-Type": "multipart/form-data" },
          });
          console.log(attachPics.data);
        } catch (error) {
          setError(true);
          console.log(error.response);
        }
      }*/
       
  }catch (err) {
    setError(true);
  }
};
  return (
    <>
     
      <div class="templatemo-content-widget white-bg col-2">
        <i class="fa fa-times"></i>
        <div class="media margin-bottom-30">
         
         
          <header class="text-center">
            <div class="square"></div>
            <h1>Rent and Flex</h1>
            <p>Excellent Estate Managers</p>
          </header>
          {error && <span style={{ color: "red" }}> Post is unsuccessful</span>}
           <span style={{ color: "red" }}> PDF Documents Only</span>

          <form
            id="contact"
            onSubmit={writeHandler}
            encType="multipart/form-data"
            method="POST"
            input
            value="csrf_token()"
            className="templatemo-login-form"
          >
            <div class="form-group">
              <input
                type="text"
                name="title"
                value={title}
                className="form-control"
                placeholder="Enter File Name"
                required="true"
                onChange={(e) => titleRef(e.target.value)}
              />
            </div>

            <br />
            <div class="form-group">
              <input
                class="form-control"
                type="file"
                className="subscription-input-form"
                name="file"
                onChange={fileSelectedHandler}
              />
            </div>
            <div class="form-group">
              <button type="submit" class="templatemo-blue-button width-100">
                Post
              </button>
            </div>
          </form>
        </div>
      </div>
      ;
    </>
  );
}

export default FileUpload;
