import "./App.css";
import "react-datetime/css/react-datetime.css";

import { Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Listing from "./pages/listing";
import Login from "./pages/login";
import Register from "./pages/register";
import Write from "./pages/write";
import { useContext } from "react";
import Input from "./pages/input";
import Post from "./pages/post";
import Single from "./pages/single";
import Blog from "./pages/blog";
import Payment from "./pages/payment";
import Tenants from "./pages/tenants";
import ManageUsers from "./pages/manageUsers";
import Defaults from "./pages/defaults";
import Market from "./pages/market";
import AddProperty from "./pages/addProperty";
import AddTenant from "./pages/addTenant";
import AddRent from "./pages/addRent";
import AddExpense from "./pages/addExpense";
import { AuthContext } from "./context/authContext";
import Property from "./pages/property";
import Article from "./pages/article";
import PropertyList from "./list/propertyList";
import SingleMarket from "./pages/singleMarket";
import RegisterAdmin from "./pages/registerAdmin";
import AllMarket from "./pages/allMarket";
import PrintPage from "./pages/printPage";
import Users from "./pages/users";
import SingleDefaults from "./pages/singleDefaults";
import SinglePayment from "./pages/singlePayment";
import Account from "./pages/Account";
import Admin from "./pages/Admin";
import Statement from "./pages/statement";
import AddLease from "./pages/addLease";
import ConfirmPassword from "./resetPassword/confirmPassword";
import ResetPassword from "./resetPassword/resetEmail";
import Recovered from "./resetPassword/Recovered";
import FilePage from "./pages/filePage";

function App() {
  const { currentUser } = useContext(AuthContext);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={currentUser ? <Home /> : <Login />} />
        <Route path="/admin" element={currentUser ? <Admin /> : <Login />} />

        <Route path="/propertyList" element={<PropertyList />} />
        <Route path="/accounts" element={<Account />} />
        <Route path="/print" element={<PrintPage />} />


        <Route
          path="/files"
          element={currentUser ? <FilePage /> : <Login />}
        />

        <Route
          path="/listing"
          element={currentUser ? <Listing /> : <Login />}
        />
        <Route
          path="/property/*"
          element={currentUser ? <Single /> : <Login />}
        />
        <Route
          path="/property/"
          element={currentUser ? <Property /> : <Login />}
        />
        <Route
          path="/addProperty/*"
          element={currentUser ? <AddProperty /> : <Login />}
        />
        <Route
          path="/addTenant/*"
          element={currentUser ? <AddTenant /> : <Login />}
        />
        <Route
          path="/addRent/*"
          element={currentUser ? <AddRent /> : <Login />}
        />
        <Route
          path="/addExpense/"
          element={currentUser ? <AddExpense /> : <Login />}
        />
        <Route
          path="/profile/"
          element={currentUser ? <Market /> : <Login />}
        />
        <Route
          path="/addLease/"
          element={currentUser ? <AddLease /> : <Login />}
        />
<Route
          path="/reports/"
          element={currentUser ? <Statement /> : <Login />}
        />

        <Route
          path="/reset-password/"
          element={ <ResetPassword /> }
        />
       
        <Route
          path="/confirm-password/"
          element={ <ConfirmPassword /> }
        />
       <Route
          path="/recovered"
          element={ <Recovered /> }
        />


        <Route
          path="/allListing/"
          element={currentUser ? <AllMarket /> : <Login />}
        />
       
        <Route
          path="/profile/*"
          element={currentUser ? <SingleMarket /> : <Login />}
        />
        <Route
          path="/payment/"
          element={currentUser ? <Payment /> : <Login />}
        />
        <Route
          path="/payment/*"
          element={currentUser ? <SinglePayment /> : <Login />}
        />
        <Route
          path="/tenants/*"
          element={currentUser ? <Tenants /> : <Login />}
        />
         <Route
          path="/defaults/*"
          element={currentUser ? <SingleDefaults /> : <Login />}
        />
        <Route
          path="/defaults/"
          element={currentUser ? <Defaults /> : <Login />}
        />
        <Route
          path="/users/"
          element={currentUser ? <ManageUsers /> : <Login />}
        />
        <Route path="/user/" element={currentUser ? <Users /> : <Login />} />
        <Route path="/blog/*" element={currentUser ? <Article /> : <Login />} />
        <Route path="/blog/" element={currentUser ? <Blog /> : <Login />} />
        <Route path="/login/" element={<Login />} />
        <Route
          path="/register/"
          element={currentUser ? <Register /> : <Login />}
        />
        <Route
          path="/registration"
          element={currentUser ? <RegisterAdmin /> : <Login />}
        />
        <Route path="/write/" element={currentUser ? <Write /> : <Login />} />
        <Route path="/post/" element={currentUser ? <Post /> : <Login />} />
        <Route path="/input/" element={currentUser ? <Input /> : <Login />} />
      </Routes>
    </div>
  );
}

export default App;
