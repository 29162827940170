import { createContext, useState, useEffect, useContext } from "react";
import http from "../components/http";
import Login from "../pages/login";
import ResetPassword from "../resetPassword/resetEmail";
import ConfirmPassword from "../resetPassword/confirmPassword";
import Recovered from "../resetPassword/Recovered";

export const AuthContext = createContext();

export const useAuth =()=> useContext(AuthContext)

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

 const [page, setPage] = useState('login')
const [email, setEmail] = useState('')
const [otp,setOTP] = useState('')

  const login = async (inputs) => {
   try{const res = await http.post("/auth/login", inputs);
    console.log(res);
    setCurrentUser(res.data.user);} 
   // 
   catch(err){
    console.log(err)
   }

  };

  function NavigateComponents(){
    if(page ==='login') return <Login/>
    if(page ==='otp') return <ResetPassword/>
    if(page==='reset') return <ConfirmPassword/>
  return <Recovered/>    
  }


  const logOut = async () => {
    await http.post("/auth/logout");
    setCurrentUser(null);
  };

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, login, logOut, page,setPage, email, setEmail, otp,setOTP }}>
      {children}
    </AuthContext.Provider>
  );
};
