import { useEffect, useState } from "react"

 const useFunctions =(fn)=>{
    const [data, setData] = useState([])
const [isLoading, setIsLoading] = useState(true)
useEffect (()=>{
 const fetchData = async ()=>{
     setIsLoading(true)

     try{
          const response = await fn();
          setData(response)
     }       
     catch(error){  
           console.log(error)
     }
     finally{
         setIsLoading(false)
     }
 }
 fetchData();
},[fn]
)
return {data, isLoading}

}



export default useFunctions;