import React from "react";
import useSWR, { mutate } from "swr";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import http, { marketUrlEndpoint } from "../components/http.jsx";
import toast from "react-hot-toast";
import useFunctions from "../lib/useFunctions.jsx";
import { useProperties } from "../data/property.js";

const handleDelete = async ({ id }) => {
  try {
    await http.delete(marketUrlEndpoint + id);
    mutate();
    toast.success("Market Removed Successfully");
  } catch (error) {
    toast.error(error.message, {
      duration: 10,
    });
  }
};

function MarketTable({ market }) {
  let num = 1;
  const {data:property,isLoading} = useFunctions(useProperties)
  console.log(property)
  if(isLoading) <p>"Property is Loading"</p>

  return (
    <div>
      {/* The table */}

      <table class="table table-striped table-bordered templatemo-user-table">
        <thead>
          <tr>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                # <span class="caret"></span>
              </a>
            </td>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                Business Name <span class="caret"></span>
              </a>
            </td>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                Address <span class="caret"></span>
              </a>
            </td>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                Phone <span class="caret"></span>
              </a>
            </td>
            <td>
              <a href="" class="white-text templatemo-sort-by">
                Email <span class="caret"></span>
              </a>
            </td>
            <td>Edit</td>
            <td>View</td>
            <td>Delete</td>
          </tr>
        </thead>
        {market &&
          market.map((market, key) => {
              const properties =(market) =>{
           return property && property.filter((p)=>p.firm ===market.title)
                       }
             const length = properties(market) ||0
            return (
              <tbody key={key}>
                <tr key={key}>
                  <td>{num++}</td>
                  <td><Link
                      to={`/property`}
                      state={market.title}
                       >{market.title}
                       <br/>
                       {
                        length.length
                       }
                       
                       </Link></td>
                  <td>{market.address}</td>
                  <td>{market.phone}</td>
                  <td>{market.email}</td>
                  <td>
                    <Link
                      to={`/write/`}
                      state={market}
                      class="templatemo-edit-btn"
                    >
                      {" "}
                      Edit
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/profile/${market.slug}`}
                      state={market}
                      className="templatemo-link"
                    >
                      View
                    </Link>
                  </td>
                  <td>
                    <Link
                      to=""
                      onClick={() => handleDelete(market)}
                      class="templatemo-edit-btn"
                    >
                      Delete
                    </Link>
                  </td>{" "}
                </tr>
              </tbody>
            );
          })}
      </table>
      {/* Pagination */}
    </div>
  );
}

export default MarketTable;
