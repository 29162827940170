import React, { useState, useEffect } from "react";
import http, { propertyUrlEndpoint } from "../components/http.jsx";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Details from "../components/details";
import SidebarAdmin from "../components/sidebarAdmin";
import NavAdmin from "../components/navAdmin";
import Pagination from "../components/pagination.jsx";

const handleDelete = async ({ id }) => {
  try {
    await http.delete(propertyUrlEndpoint + id);
    toast.success("Property Deleted Successfully");
  } catch (error) {
    toast.error(error.message, {
      duration: 10,
    });
  }
};

function Listing() {
  let num = 1;

  // Search input
  const [inputs, setInputs] = useState([]);
  //

  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = posts.slice(firstIndex, lastIndex);

  const paginate = (numbers) => setCurrentPage(numbers);

  

  {useEffect(() => {
    const fetchPosts = async () => {
      //  const res = await axios.get("/posts");
      const res = await http.get(`property`);
      setInputs(res.data);
      setPosts(res.data);
    };
    fetchPosts();
  }, []);
}
  //for Search
  const changeHandler = (e) => {
    setPosts(
      inputs.filter((f) => f.title.toLowerCase().includes(e.target.value))
    );
  };

  

  return (
    <div>
      <div class="templatemo-flex-row">
        <SidebarAdmin />
        <div class="templatemo-content col-1 light-gray-bg">
          <NavAdmin />
          <div class="templatemo-content-container">
            <Details
              th1={"Total Properties"}
              th2={"Total Cities"}
              th3={"Total States"}
              th4={"Vacant Properties"}
              total={posts.length}
            />

            {/* The table */}
            <div class="templatemo-content-widget templatemo-login-widget templatemo-register-widget white-bg">
              <p>
                We are Growing,{" "}
                <strong>
                  <Link to="/addProperty" class="blue-text">
                    List the new property here
                  </Link>
                </strong>
              </p>
            </div>
            {/* The search */}
            <form class="templatemo-search-form" role="search">
              <button type="submit" class="fa fa-search"></button>
              <input
                type="text"
                class="form-control"
                placeholder="Search Property here"
                name="srch-term"
                id="srch-term"
                onChange={changeHandler}
              />
            </form>
            <div class="templatemo-content-widget no-padding">
              <div class="panel panel-default table-responsive">
                <table class="table table-striped table-bordered templatemo-user-table">
                  <thead>
                    <tr>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          # <span class="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          Property <span class="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          City <span class="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          Landlord <span class="caret"></span>
                        </a>
                      </td>
                      <td>
                        <a href="" class="white-text templatemo-sort-by">
                          Phone <span class="caret"></span>
                        </a>
                      </td>
                      <td>Edit</td>
                      <td>View</td>
                      <td> Tenants</td>
                      <td>Delete</td>
                    </tr>
                  </thead>
                  {records &&
                    records.map((m, key) => {
                      return (
                        <tbody key={key}>
                          <tr key={key}>
                            <td>{num++}</td>
                            <td><Link
                      to={`/property/${m.slug}`}
                      state={m}
                       >{m.title}
                                             
                       </Link></td>
                            <td>{m.city}</td>
                            <td>{m.landLord}</td>
                            <td>{m.phone}</td>
                            <td>
                              <Link
                                to={`/addProperty/`}
                                state={m}
                                class="templatemo-edit-btn"
                              >
                                {" "}
                                Edit
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/blog/${m.slug}`}
                                state={m}
                                className="templatemo-link"
                              >
                                View
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/tenants/${m.slug}`}
                                className="templatemo-edit-btn"
                              >
                                Tenants
                              </Link>
                            </td>
                            <td>
                      <Link
                        to=""
                        onClick={() => handleDelete(m)}
                        class="templatemo-edit-btn"
                      >
                        Delete
                      </Link>
                    </td>
                          </tr>
                        </tbody>
                      );
                    })}
                </table>
                {/* Pagination */}
             
                <Pagination
                order={posts.length}
                recordsPerPage={recordsPerPage}
                paginate={paginate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Listing;
