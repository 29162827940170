import React, { useState, useContext, useEffect } from "react";
import Nav from "../components/nav";
import http from "../components/http";
import { AuthContext } from "../context/authContext";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { useFirms } from "../data/market";
import useFunctions from "../lib/useFunctions";
import { useProperties, useProperty } from "../data/property";
import TenantExcel from "../components/tenantExcel";

function AddTenant() {
  const post = useLocation().state;
  //Fetch params
  const location = useLocation();
 
  const property = location.pathname.split("/")[2];
  
  const [title, titleRef] = useState(post?.title || "");
  const { currentUser } = useContext(AuthContext);

  const [origin, originRef] = useState(post?.origin || "");
  const [rent, rentRef] = useState(post?.rent || "");

  const [phone, phoneRef] = useState(post?.phone || "");
  const [description, describeRef] = useState(post?.description || "");
  const [type, setType] = useState(post?.type || "");
  const [units, unitsRef] = useState(post?.units || "");
  const [error, setError] = useState(false);

  //oga
  const [firms, setFirms] = useState(null)
  const oga = currentUser.role ===3
  
  //Firms
  const {data:market, isLoading:firmLoading}= useFunctions(useFirms)
  const [data, setData] = useState([])
 const [isLoading, setIsLoading] = useState(true)
 // const {data:allProperty, isLoading:propertyLoading}= useFunctions(useProperty,firms)
 
 useEffect (()=>{
  const fetchData = async ()=>{
      setIsLoading(true)
 
      try{
           const response = await http.get( `property/${firms||'Premier RecordIT'}`);
           setData(response)
      }       
      catch(error){  
            console.log(error)
      }
      finally{
          setIsLoading(false)
      }
  }
  fetchData();
 },[firms]
 )
  //submit handler

  const writeHandler = async (e) => {
    e.preventDefault();
    setError(false);

    const postItems = {
      title,
      phone: "-",
      type: "-",
      rent: "-",
      origin: "-",
      description: "-",
      units: "-",
      property,
      firm: oga?firms:currentUser.firm,
    };

    try {
      post
        ? await http.put(`/tenant/${post.id}`, {
            ...post,
            title,
            phone,
            type,
            rent,
            origin,
            description,
            units,
            property,
            firm: oga?firms:currentUser.firm,
          })
        : (await http.post(`/tenant/`, postItems)) && titleRef("");

      toast.success("Tenant Added Successfully");
      // window.location.replace("/property");
    } catch (error) {
      setError(true);
      toast.error(error.message, {
        duration: 100,
      });
    }
  };

  return (
    <>
      <Nav />
      <div class="templatemo-content col-1 light-gray-bg">
        <div class="templatemo-content-widget templatemo-login-widget white-bg">
          <header class="text-center">
            <div class="square"></div>
            <h1>Rent and Flex</h1>
            <p>Excellent Property Management</p>
          </header>
          {error && <span style={{ color: "red" }}> Post is unsuccessful</span>}

          <form
            id="contact"
            onSubmit={writeHandler}
            encType="multipart/form-data"
            method="POST"
            value="csrf_token()"
            className="templatemo-login-form"
          >
      
      {oga&&           <div className="form-group">
          <select
                onChange={(e) => setFirms(e.target.value)}
                className="form-control"
                value={firms}
              >
                {market
                  ? market.map((option) => (
                      <>
                        {" "}
                        <option value="" defaultValue="selected" hidden="hidden">
                          Choose here
                        </option>
                        <option key={option.id} value={option.title}>
                          {option.title}
                        </option>
                      </>
                    ))
                  : isLoading && <p>Market Names are Loading...</p>}
              </select></div>}
            <div class="form-group">
              <input
                class="form-control"
                type="name"
                name="title"
                placeholder="Enter Name of Tenant"
                value={title}
                required={true}
                onChange={(e) => titleRef(e.target.value)}
              />
            </div>
            {post && (
              <>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="origin"
                    value={origin}
                    placeholder="Enter Origin"
                    autocomplete="on"
                    required
                    onChange={(e) => originRef(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <input
                    class="form-control"
                    type="number"
                    name="rent"
                    placeholder="Enter Rent"
                    value={rent}
                    required
                    onChange={(e) => rentRef(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    name="phone"
                    placeholder="Phone of the Tenant"
                    value={phone}
                    required
                    onChange={(e) => phoneRef(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <input
                    type="number"
                    class="form-control"
                    name="units"
                    value={units}
                    placeholder="Enter Number of units"
                    required
                    onChange={(e) => unitsRef(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    name="halls"
                    value={description}
                    placeholder="Enter Description of the Apartment"
                    required
                    onChange={(e) => describeRef(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <div class="checkbox squaredOne">
                    <input
                      type="radio"
                      value={type}
                      name="duration"
                      id="office"
                      onChange={(e) => setType(e.target.value)}
                    />
                    <label htmlFor="office">
                      <span></span>Office
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <div class="checkbox squaredTwo">
                    <input
                      type="radio"
                      value={type}
                      name="duration"
                      id="residence"
                      onChange={(e) => setType(e.target.value)}
                    />
                    <label htmlFor="residence">
                      <span></span>Residence
                    </label>
                  </div>
                </div>
              </>
            )}
            <div class="form-group">
              <button type="submit" class="templatemo-blue-button width-100">
                Post
              </button>
            </div>
          </form>
        </div>
        <TenantExcel property={property} />
      </div>
    </>
  );
}

export default AddTenant;
